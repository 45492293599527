import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "catch-em-being-good"
    }}>{`Catch 'em being good`}</h1>
    <p>{`We all like to have our efforts and hard work acknowledged. When your teenager is behaving well, give them some `}<em parentName="p">{`positive`}</em>{` consequences. Use a variety of these, including praise, attention and privileges.`}</p>
    <p>{`Notice when your teenager is following the rules and praise and `}<em parentName="p">{`thank them for it`}</em>{`. Tell them you appreciate it, and try to do something special for them. This strategy often works best if you ‘catch’ your teenager behaving well. It’s always nice to hear a “well done” or “that’s great!” when you’re not expecting it.`}</p>
    <h3 {...{
      "id": "here-are-a-few-things-that-teenagers-like-to-hear-from-their-parents"
    }}>{`Here are a few things that teenagers like to hear from their parents:`}</h3>
    <ul>
      <li parentName="ul">{`“Wow, your room looks so tidy. Nice job!”`}</li>
      <li parentName="ul">{`“Thanks for getting your homework done first.”`}</li>
      <li parentName="ul">{`A text message saying “Thanks for getting out of bed this morning without my help. It made my morning!”`}</li>
    </ul>
    <SingleImage gridSize={7} smallGridSize={10} src="/images/m3/17-02.svg" alt="Talk" mdxType="SingleImage" />
    <SingleImage gridSize={7} smallGridSize={10} src="/images/m3/17-01.svg" alt="Talk" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      